import axios from "axios";
import Cookies from 'universal-cookie';

const cookies = new Cookies();
const http = axios.create({
    baseURL: process.env.NODE_ENV === 'production' ? process.env.REACT_APP_PROD_API_URL : process.env.REACT_APP_DEV_API_URL,
});


export async function promocodeManagement(user, promoCodeName, ticketTypesIds) {

    const currentUser = cookies.get('currentUser');

    const data = {
        ...user,
        promoCodeName,
        ticketTypesIds
    }

    return await
        http
            .post(`/events/promocodeManagement`, data, {
                headers: { 'authorization': currentUser ? `Bearer ${currentUser.token}` : `` }
            })
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                throw error;
            });
}

export async function getPromocodeDetails(user) {

    const currentUser = cookies.get('currentUser');

    return await
        http
            .post(`/events/getPromocodeDetails`, user, {
                headers: { 'authorization': currentUser ? `Bearer ${currentUser.token}` : `` }
            })
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                throw error;
            });
}

export async function getPromoCodeTicketTypes(promoCodeName, accountid) {

    const currentUser = cookies.get('currentUser');

    return await
        http
            .post(`/events/getPromoCodeTicketTypes`, { promoCodeName, accountid } , {
                headers: { 'authorization': currentUser ? `Bearer ${currentUser.token}` : `` }
            })
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                throw error;
            });
}
